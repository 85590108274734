import React from 'react'
import {AnnouncementProps} from '../../types/AnnouncementProps'
import {Box, Grid, Typography} from '@material-ui/core'
import {useStyles} from './AnnouncementStyle'
import CrossIcon from '@atlaskit/icon/glyph/cross'


const Announcement = (props: AnnouncementProps) => {

    const classes = useStyles()

    return (
        <>
            {!props.isDismissed &&
                <Grid container className={classes.container}>
                    {props.closeButtonHandler && <Box className={classes.closeIcon}
                                                      onClick={props.closeButtonHandler}>
                        <CrossIcon label="close"/>
                    </Box>
                    }
                    <Grid container>
                        <Grid item xs={5}>
                            <img alt="" className={classes.image} src={props.imagePath}/>
                        </Grid>
                        <Grid item xs={7}>
                            <Grid item><Typography
                                className={classes.title}>{props.title}</Typography></Grid>
                            <Grid item>
                                <Typography
                                    className={classes.subtitle}>
                                    {props.subtitle}
                                </Typography>
                            </Grid>
                            <Grid item>
                                {props.children}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </>
    )
}
export default Announcement
