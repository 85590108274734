import React, {useEffect, useState} from 'react'
import {Box, Grid, Link, Typography} from '@material-ui/core'
import {useStyles} from './AnnouncementSmallUpdatesStyles'
import {hasUserDismissAnnouncementUpdates, setUserDismissAnnouncementUpdates} from '../../modules/TrelloPersistence'
import {TrackActionEvent} from 'trello-shared-resources/dist'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import {AnnouncementTypeProps} from '../../types/AnnouncementTypeProps'
import imageUpdates from '../../assets/images/clock-box_transparent.png'
import {resizeIframe} from '../../modules/Utils'

export const AnnouncementSmallUpdates = (props: AnnouncementTypeProps) => {

    const {licenseDetails} = props
    const [isDimissed, setIsDimissed] = useState<boolean>(true)
    const [isLoggedUserInOrah, setIsLoggedUserInOrah] = useState(false)
    const classes = useStyles()
    const trelloIframeContext = licenseDetails.trelloIframeContext
    const trelloContext = trelloIframeContext.getContext()


    /**
     * Handler link find out more
     */
    const handlerFindOutMore = () => {
        TrackActionEvent('Find Out More link', trelloContext, {
            board_id: trelloContext.board,
            member_id: trelloContext.member
        })
    }

    /**
     * Handler to open the checkout
     */
    const handlerUpgradeNow = async () => {
        TrackActionEvent('Settings link', trelloContext, {
            board_id: trelloContext.board,
            member_id: trelloContext.member,
            action: 'upgrade_link'
        })
        trelloIframeContext.modal({
            url: isLoggedUserInOrah ? './index.html?apptype=billingSettings' : './index.html?apptype=manageMyAccount',
            fullscreen: true,
            resizable: false,
            title: `${process.env.REACT_APP_TRELLO_APP_NAME} Settings`,
            accentColor: '#EBEDF0'
        })
    }

    /**
     * Handler close announcement
     */
    const closeButtonHandler = async () => {
        setIsDimissed(true)
        resizeIframe('#contentCardBackSection', 200, trelloIframeContext)
        await setUserDismissAnnouncementUpdates(licenseDetails)
    }


    useEffect(() => {
        hasUserDismissAnnouncementUpdates(licenseDetails.trelloIframeContext)
            .then((isDimissed: boolean) => {
                setIsDimissed(isDimissed)
                if (!isDimissed) {
                    licenseDetails.getUser().then((user: any) => setIsLoggedUserInOrah(user !== undefined))
                }
            })
    }, [licenseDetails])

    return <>
        {!isDimissed &&
            <Grid container className={classes.container}>
                <Box className={classes.closeIcon}
                     onClick={closeButtonHandler}>
                    <CrossIcon label="close"/>
                </Box>

                <Grid container>
                    <Grid item xs={3}>
                        <img alt="" className={classes.image} src={imageUpdates}/>
                    </Grid>
                    <Grid item xs={9}>
                        <Grid item><Typography
                            className={classes.title}>Time Tracking for Trello has a whole new look and new features
                            🎉</Typography></Grid>
                        <Grid item>
                            <Link href="https://docs.orahapps.com/en/articles/56-time-tracking-reporting"
                                  target="_blank" className={classes.linkFinOutMore} onClick={handlerFindOutMore}
                                  underline="none">Find
                                out more</Link>
                            <Link href="#" className={classes.link} onClick={handlerUpgradeNow}>Upgrade Now</Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        }
    </>

}