import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
            subtitleBoldText: {
                fontFamily: 'SF UI Text Bold',
                fontSize: 14
            },
            linkFinOutMore: {
                fontSize: 14,
                fontFamily: 'SF UI Text Regular',
                marginRight: 52
            },
            link: {
                fontSize: 14,
                fontFamily: 'SF UI Text Regular'
            },
            container: {
                padding: '24px 24px 5px 0px',
                marginBottom: 13,
                backgroundColor: 'rgb(234, 229, 255)'
            },
            title: {
                fontFamily: 'SF UI Text SemiBold',
                color: '#172B4D',
                fontSize: 14,
                marginBottom: 14
            },
            image: {
                maxWidth: '100%',
                marginTop: -15
            },
            closeIcon: {
                cursor: 'pointer',
                position: 'absolute',
                float: 'right',
                right: 6,
                marginTop: '-20px'
            },

        }
    )
)

export {useStyles}
