import React, { useState } from 'react'
import ReportDialog from '../components/report/ReportDialog'
import { ButtonItem } from '@atlaskit/side-navigation'
import GraphLineIcon from '@atlaskit/icon/glyph/graph-line'
import { getMenuContent } from 'trello-shared-resources/dist/services/MenuService'
import { Grid } from '@material-ui/core'
import { useStyles } from './ReportStyles'
import LeftBar from 'trello-shared-resources/dist/components/leftBar/LeftBar'

import { TimeTrackingMenuEnum } from '../types/TimeTrackingMenuEnum'
import { renderTrelloContextMissing } from 'trello-shared-resources/dist/components/powerUp/ModalOutputs'

/**
 * The main Report screen. This shows the left bar + settings tabs + ReportDialog
 */
const Report = (props: { licenseDetails: any, selectedMenuItem: string }) => {
    const classes = useStyles()

    const licenseDetails = props.licenseDetails
    const [selectedMenuItem, setSelectedMenuItem] = useState(props.selectedMenuItem || TimeTrackingMenuEnum.TIME_TRACKING_REPORT)

    if (!licenseDetails || !licenseDetails.trelloIframeContext) {
        return renderTrelloContextMissing()
    }

    let mainDialogContent = getMenuContent(licenseDetails, selectedMenuItem)
    if (!mainDialogContent) {
        if ((!licenseDetails.isAuthorized || !licenseDetails.isLicensed())) {
            licenseDetails.trelloIframeContext.modal({
                url: './index.html?apptype=error',
                fullscreen: true,
                resizable: false,
                title: 'Time Tracking and Reporting by Kolekti',
                accentColor: '#EBEDF0'
            })
            return (<div>Loading...</div>)
        }
        mainDialogContent = (
            <Grid item xs={8} md={10} xl={9}>
                <div className="popup__wrapper">
                    <ReportDialog licenseDetails={licenseDetails}/>
                </div>
            </Grid>
        )
    }

    const ttButtons = [
        <ButtonItem isSelected={selectedMenuItem === TimeTrackingMenuEnum.TIME_TRACKING_REPORT}
                    iconBefore={<GraphLineIcon primaryColor={'#42526E'} label="Time Tracking & Reporting"/>}
                    key={TimeTrackingMenuEnum.TIME_TRACKING_REPORT}
                    onClick={() => {
                        setSelectedMenuItem(TimeTrackingMenuEnum.TIME_TRACKING_REPORT)
                    }}>
            Time Tracking Reports
        </ButtonItem>
    ]

    return (
        <Grid container className={classes.container}>
            <LeftBar selectedMenuItem={selectedMenuItem} setSelectedMenuItem={setSelectedMenuItem}
                     externalButtons={ttButtons}/>
            {mainDialogContent}
        </Grid>
    )
}

export default Report