import React, {useEffect} from 'react'
import {renderTrelloContextMissing} from 'trello-shared-resources/dist/components/powerUp/ModalOutputs'
import CardBackSectionErrorMessage from './CardBackSectionErrorMessage'
import CardBackSectionButtons from './CardBackSectionButtons'
import {AnnouncementUpdates} from '../common/AnnouncementUpdates'
import {AnnouncementSmallUpdates} from '../common/AnnouncementSmallUpdates'
import {resizeIframe} from '../../modules/Utils'

/**
 * Main container component for the section at the back of the card
 */
const CardBackSection = (props: { licenseDetails: any }) => {

    const licenseDetailsContext = props.licenseDetails

    useEffect(() => {
        resizeIframe('#contentCardBackSection', 200, licenseDetailsContext.trelloIframeContext)
    }, [licenseDetailsContext.isAuthorized, licenseDetailsContext.trelloIframeContext])

    if (!licenseDetailsContext.trelloIframeContext) {
        return renderTrelloContextMissing()
    }

    if (!licenseDetailsContext.isAuthorized || !licenseDetailsContext.isLicensed()) {
        return (
            <><AnnouncementSmallUpdates licenseDetails={licenseDetailsContext}/>
                <CardBackSectionErrorMessage licenseDetails={licenseDetailsContext}/></>)

    }

    return <>
        <AnnouncementUpdates licenseDetails={licenseDetailsContext}/>
        <CardBackSectionButtons licenseDetails={licenseDetailsContext}/>
    </>
}

export default CardBackSection