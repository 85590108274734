import React, {useState} from 'react'
import Button from '@atlaskit/button'
import {useStyles} from './CardBackSectionStyles'
import {CardBackSectionMessageProps} from '../../types/CardBackSectionMessageProps'
import {Box} from '@material-ui/core'
import {resizeIframe} from '../../modules/Utils'
import ErrorStep from 'trello-shared-resources/dist/components/onboarding/error/ErrorStep'

/**
 * Component is showed when a user has not a license or unauthorized
 * */
const CardBackSectionErrorMessage = (props: CardBackSectionMessageProps) => {
    const classes = useStyles()

    const [isErrorMessageCollapsed, setIsErrorMessageCollapsed] = useState(true)
    const licenseDetailsContext = props.licenseDetails
    licenseDetailsContext.smallErrorMessageWindow = true

    const buttonHandler = () => {
        setIsErrorMessageCollapsed(!isErrorMessageCollapsed)
        resizeIframe('#contentCardBackSection', 200, licenseDetailsContext.trelloIframeContext)
    }

    const textButton = isErrorMessageCollapsed ? 'Show details' : 'Hide details'
    let mainContent = null
    if (isErrorMessageCollapsed) {
        mainContent = null
    } else if (!licenseDetailsContext.isAuthorized) {
        mainContent = <ErrorStep licenseDetails={licenseDetailsContext}/>
    } else if (!licenseDetailsContext.isLicensed()) {
        mainContent = <Box className={classes.unpaidContainer}>
            <ErrorStep licenseDetails={licenseDetailsContext}/>
        </Box>
    }

    return <>
        <Button className={classes.errorButton} onClick={buttonHandler}>{textButton}</Button>
        {mainContent}
    </>
}

export default CardBackSectionErrorMessage