import React, {useEffect, useState} from 'react'
import Announcement from './Announcement'
import {Link} from '@material-ui/core'
import imageUpdates from '../../assets/images/clock-box_transparent.png'
import {useStyles} from './AnnouncementUpdatesStyles'
import {hasUserDismissAnnouncementUpdates, setUserDismissAnnouncementUpdates} from '../../modules/TrelloPersistence'
import {supportUrl, TrackActionEvent} from 'trello-shared-resources/dist'
import {resizeIframe} from '../../modules/Utils'
import {AnnouncementTypeProps} from '../../types/AnnouncementTypeProps'

export const AnnouncementUpdates = (props: AnnouncementTypeProps) => {

    const {licenseDetails} = props
    const [isDimissed, setIsDimissed] = useState<boolean>(true)
    const [isLoggedUserInOrah, setIsLoggedUserInOrah] = useState(false)
    const [isEvalLicensed, setIsEvalLicensed] = useState(false)
    const classes = useStyles()
    const trelloIframeContext = licenseDetails.trelloIframeContext
    const trelloContext = trelloIframeContext.getContext()


    /**
     * Handler link find out more
     */
    const handlerFindOutMore = () => {
        TrackActionEvent('Find Out More link', trelloContext, {
            board_id: trelloContext.board,
            member_id: trelloContext.member
        })
    }

    /**
     * Handler to open the checkout
     */
    const handlerUpgradeNow = async () => {
        TrackActionEvent('Settings link', trelloContext, {
            board_id: trelloContext.board,
            member_id: trelloContext.member,
            action: 'upgrade_link'
        })
        trelloIframeContext.modal({
            url: isLoggedUserInOrah ? './index.html?apptype=billingSettings' : './index.html?apptype=manageMyAccount',
            fullscreen: true,
            resizable: false,
            title: `${process.env.REACT_APP_TRELLO_APP_NAME} Settings`,
            accentColor: '#EBEDF0'
        })
    }

    /**
     * Handler close announcement
     */
    const closeButtonHandler = async () => {
        setIsDimissed(true)
        resizeIframe('#contentCardBackSection', 200, licenseDetails.trelloIframeContext)
        await setUserDismissAnnouncementUpdates(licenseDetails)
    }


    useEffect(() => {
        setIsEvalLicensed(licenseDetails.isOnlyEvalLicensed())
        hasUserDismissAnnouncementUpdates(trelloIframeContext)
            .then((isDimissed: boolean) => {
                setIsDimissed(isDimissed)
                if (!isDimissed) {
                    resizeIframe('#contentCardBackSection', 200, licenseDetails.trelloIframeContext)
                    if (isEvalLicensed) {
                        licenseDetails.getUser().then((user: any) => setIsLoggedUserInOrah(user !== undefined))
                    }
                }
            })
    }, [licenseDetails, isEvalLicensed, trelloIframeContext])

    return <Announcement imagePath={imageUpdates}
                         title={'Time Tracking for Trello has a whole new look 🎉'}
                         subtitle={<>It’s now easier for you to log time and access your time logs. You can even mark
                             time as “billable” so you can quickly identify client work!</>}
                         isDismissed={isDimissed}
                         closeButtonHandler={closeButtonHandler}>
        <>
            <Link href="https://docs.orahapps.com/en/articles/56-time-tracking-reporting"
                  target="_blank" className={classes.link} onClick={handlerFindOutMore} style={{marginRight: 52}}>Find
                out More</Link>
            {isEvalLicensed ?
                <Link href="#" className={classes.link} onClick={handlerUpgradeNow}>Upgrade Now</Link> :
                <Link href={supportUrl} target="_blank" className={classes.link}>Contact Support</Link>}
        </>
    </Announcement>

}