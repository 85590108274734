import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: '24px 24px 16px 0',
            marginBottom: 13,
            backgroundColor: 'rgb(234, 229, 255)'
        },
        title: {
            fontFamily: 'SF UI Text SemiBold',
            color: '#172B4D',
            fontSize: 17,
            marginBottom: 16
        },
        subtitle: {
            marginBottom: 16,
            fontFamily: 'SF UI Text Regular',
            fontSize: 14,
        },
        image: {
            maxWidth: '100%',
            transform: 'scale(1.3)'
        },
        closeIcon: {
            cursor: 'pointer',
            position: 'absolute',
            float: 'right',
            right: 6,
            marginTop: '-20px'
        },

    }),
)

export {useStyles}
