import {useEffect} from 'react'
import {initializeCapabilities, PageView, TrackActionEvent,} from 'trello-shared-resources'
import {convertSecondsToTimeDuration, getTotalTimerDuration} from '../modules/Utils'
import {storeTimers} from '../modules/Persistence'
import {showDarkThemeNotSupportedMessage} from 'trello-shared-resources/dist/services/DarkThemeService'

/**
 *  The ConnectorView is used to tell Trello what capabilities your
 *  app should have. Use this Connector to add new menu items, buttons
 *  and other similar capabilities.
 */
const ConnectorView = () => {

    let collectTimersRunningLaunched = false

    const capabilities = {
        'card-back-section': function (trelloContext, opts) {
            showDarkThemeNotSupportedMessage(trelloContext)
            return {
                title: 'Time Tracking and Reporting',
                icon: './icons/tt-logo-grey.svg',
                content: {
                    type: 'iframe',
                    url: trelloContext.signUrl('./index.html?apptype=card-back-section'),
                    height: 50
                }
            }
        },
        // Creates one or more buttons on the board (top-right)
        'board-buttons': function (trelloContext) {
            try{
                collectTimersRunning(trelloContext)
            } catch (error) {
                if(error?.message && !error.message.includes('Missing context') && !error.message.includes('Plugin disabled') &&
                    !error.message.includes('Invalid context, missing board'))
                    throw error
            }
            return [{
                text: 'Time Tracking Reports',
                icon: './icons/tt-logo-white.svg',
                callback: function (trelloContext) {
                    const trelloContextInfo = trelloContext.getContext()
                    TrackActionEvent('Button Click', trelloContextInfo, {
                        board_id: trelloContextInfo.board,
                        card_id: null,
                        button: 'Open find_logs'
                    })
                    showDarkThemeNotSupportedMessage(trelloContext)
                    return trelloContext.modal({
                        url: './index.html?apptype=report',
                        accentColor: '#EBEDF0',
                        fullscreen: true,
                        resizable: false,
                        title: 'Time Tracking and Reporting by Kolekti',
                        callback: function (t, opts) {
                            const trelloContextInfo = t.getContext()
                            TrackActionEvent('Button Click', trelloContextInfo, {
                                board_id: trelloContextInfo.board,
                                card_id: null,
                                button: 'Close find_logs'
                            })
                        }
                    })
                }
            }]
        },
        'card-badges': function (trelloContext, opts) {
            return [{
                dynamic: async () => {
                    try {
                        const timerStartedJSON = await trelloContext.get(opts.context.card, 'private', 'time_tracking_timer')
                        if (timerStartedJSON) {
                            const timer = JSON.parse(timerStartedJSON)
                            const isTimerRunning = timer.times.find(time => time.startTime && !time.endTime) !== undefined
                            const timeRunning = convertSecondsToTimeDuration(getTotalTimerDuration(timer.times), true)
                            return {
                                text: isTimerRunning ? timeRunning : 'Paused',
                                icon: `./icons/tt-logo-white.svg`,
                                color: 'purple',
                                refresh: 10
                            }
                        } else {
                            return {refresh: 15}
                        }
                    } catch (error) {
                        if(error?.message && (error.message.includes('Missing context') || error.message.includes('Plugin disabled') ||
                            error.message.includes('Invalid context, missing board'))) return {}
                        else throw error
                    }
                }
            }]
        }
    }

    /**
     * Call every 30 seconds to get the timers running from a member and store them on each card
     * @param trelloContext The context that we will use to retrieve data.
     */
    const collectTimersRunning = async (trelloContext) => {
        if(!collectTimersRunningLaunched) {
            collectTimersRunningLaunched = true
            storeTimers(trelloContext)
            setInterval(() => storeTimers(trelloContext), 30000)
        }
    }

    useEffect(() => {
        PageView('PowerUp Connector')
    }, [])

    initializeCapabilities(capabilities)

    return null
}

export default ConnectorView