import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
           
            subtitleBoldText: {
                fontFamily: 'SF UI Text Bold',
                fontSize: 14
            },
            link: {
                fontSize: 14,
                fontFamily: 'SF UI Text Regular'
            },

        }
    )
)

export {useStyles}
