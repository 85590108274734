import React, { Component } from 'react'
import { PageView, LicenseDetailsProvider, LicenseDetailsContext } from 'trello-shared-resources/dist/index'
import LogWorkFromCard from '../components/log/LogWorkFromCard'
import '../css/dialog.css'
import { Skeleton } from 'antd'
import {LogWorkProperties} from '../types/LogWorkProperties'

/**
 * The top level Report 'view'. This wraps the report functionality in the require licensing provider.
 */
class LogWork extends Component<LogWorkProperties> {

    componentDidMount() {
        PageView('LogWork')
    }

    render() {
        return <LicenseDetailsProvider>
            <LicenseDetailsContext.Consumer>
                {(licenseDetails: any) => {
                    if (licenseDetails.isLoading) {
                        return <Skeleton active={true}/>
                    }
                    return <LogWorkFromCard licenseDetails={licenseDetails} logFromTimer={this.props.logFromTimer}/>
                }}
            </LicenseDetailsContext.Consumer>
        </LicenseDetailsProvider>
    }
}

export default LogWork