import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles({
        appContainer: {
            backgroundColor: '#f4f5f7',
            height: '100%',
            minHeight: 50,
        },
        skeletonAppContainer: {
            backgroundColor: '#f4f5f7',
            height: '50px',
            overflow: 'hidden',
            minHeight: '50px',
        },
        errorButton: {
            marginBottom: 10
        },
        actionButton: {
            margin: 5
        },
        unpaidContainer: {
            '& > div': {
                height: 'unset'
            },
            '& > div > div > div': {
                height: 'unset',
                minHeight: 'unset'
            }

        }
    }
)


export { useStyles }
