import VidPlayIcon from '@atlaskit/icon/glyph/vid-play'
import VidPauseIcon from '@atlaskit/icon/glyph/vid-pause'
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle'
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle'
import { CustomThemeButton } from '@atlaskit/button'
import React, { useCallback, useEffect, useState } from 'react'
import { timerButtonTheme, useStyles } from './TimerDisplayStyles'
import { useStopwatch } from 'react-timer-hook'
import ButtonGroup from 'antd/lib/button/button-group'
import { Box, Grid } from '@material-ui/core'
import { getTimerForCard, removeTimer, resumeTimer, startTimer, stopTimer } from '../../../modules/Persistence'
import { TimerProps } from '../../../types/TimerProps'
import { getTotalTimerDuration, throwError } from '../../../modules/Utils'
import { TrackActionEvent } from 'trello-shared-resources/dist'
import { Timer } from '../../../types/Timer'
import Spinner from '@atlaskit/spinner'
import CancelModal from '../../common/CancelModal'
import { useErrorHandler } from 'react-error-boundary'

export const TimerDisplay = (props: TimerProps) => {

    const classes = useStyles()

    const {licenseDetails} = props
    const trelloIframeContext = licenseDetails.trelloIframeContext

    const [timerHasBeenInitialized, setTimerHasBeenInitialized] = useState<boolean>(false)
    const [existingTimer, setExistingTimer] = useState<Timer | undefined>(undefined)
    const [isloading, setIsLoading] = useState<boolean>(true)
    const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false)
    const [isFinishModalOpen, setIsFinishModalOpen] = useState<boolean>(false)
    const handleError = useErrorHandler()

    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        reset,
    } = useStopwatch({})

    const loadTimerData = useCallback(() => {
        getTimerForCard(licenseDetails).then(timer => {
            setIsLoading(false)
            setExistingTimer(timer)
            if (timer) {
                setTimerHasBeenInitialized(true)
                const time = new Date()
                time.setSeconds(time.getSeconds() + getTotalTimerDuration(timer.times))
                const timerRunning = timer.times.some(timeEntry => timeEntry.startTime && !timeEntry.endTime)
                reset(time, timerRunning)
            } else {
                reset(undefined, false)
                setTimerHasBeenInitialized(false)
            }
        })
    }, [licenseDetails, reset])

    useEffect(() => {
        if (!existingTimer && isloading) {
            loadTimerData()
        }
    }, [loadTimerData, existingTimer, isloading])

    const resizeIframe = () => trelloIframeContext.sizeTo('#contentCardBackSection')

    const resizeIframeWithModal = () => trelloIframeContext.sizeTo(350)

    const startTimerOnClick = async () => {
        start()
        const timer = await startTimer(licenseDetails)
        setExistingTimer(timer)
        trackTimerAction('start')
        setTimerHasBeenInitialized(true)
    }

    const pauseOnClick = async () => {
        pause()
        const timer = await stopTimer(licenseDetails)
        setExistingTimer(timer)
        trackTimerAction('pause')
    }

    const resumeOnClick = async () => {
        start()
        const timer = await resumeTimer(licenseDetails)
        setExistingTimer(timer)
        trackTimerAction('resume')
    }

    const cancelOnClick = () => {
        setIsCancelModalOpen(true)
        resizeIframeWithModal()
        trackTimerAction('cancel')
    }

    const confirmModalCancel = async () => {
        setTimerHasBeenInitialized(false)
        resizeIframe()
        await removeTimer(licenseDetails)
        reset(undefined, false)
        trackTimerAction('confirm_cancel')
    }

    const finishOnClick = () => {
        setIsFinishModalOpen(true)
        resizeIframeWithModal()
    }

    const finishModalCancel = async () => {
        resizeIframe()
        trackTimerAction('finish')
        trelloIframeContext.modal({
            url: './index.html?apptype=logFromTimer',
            accentColor: '#EBEDF0',
            fullscreen: false,
            height: 800,
            resizable: false,
            title: 'Time Tracking and Reporting by Kolekti',
            callback: loadTimerData
        })
    }

    const closeModalCancel = () => {
        setIsCancelModalOpen(false)
        resizeIframe()
        trackTimerAction('dismiss_cancel')
    }

    const closeModalFinish = () => {
        setIsFinishModalOpen(false)
        resizeIframe()
    }

    const trackTimerAction = (action: string) => {
        const trelloContextInfo = trelloIframeContext.getContext()
        TrackActionEvent('Timer', trelloContextInfo, {
            board_id: trelloContextInfo.board,
            card: trelloContextInfo.card,
            action: action
        })
    }

    return <>
        <Grid item className={classes.timerContainer}>
            {isloading && <Box className={classes.spinnerContainer}><Spinner/></Box>}
            {!timerHasBeenInitialized && !isloading &&
            <CustomThemeButton className={classes.startTimerButton} theme={timerButtonTheme}
                               onClick={() => startTimerOnClick().catch((error: any) => throwError(() => handleError(error), licenseDetails.trelloIframeContext, '#contentCardBackSection'))}
                               iconAfter={<VidPlayIcon label={'Start timer'} primaryColor={'#FFFFFF'}/>}>
                Start timer
            </CustomThemeButton>
            }
            {timerHasBeenInitialized &&
            <ButtonGroup className={classes.timerButtonGroupContainer}>
                <Box className={classes.counterContainer}>
                    {(days * 24 + hours).toString().padStart(2, '0')}:{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}
                </Box>
                {isRunning && <CustomThemeButton theme={timerButtonTheme}
                                                 onClick={() => pauseOnClick().catch((error: any) => throwError(() => handleError(error), licenseDetails.trelloIframeContext, '#contentCardBackSection'))}
                                                 iconAfter={<VidPauseIcon label={'Pause timer'} primaryColor={'#FFFFFF'}
                                                                          size={'small'}/>}>
                    Pause
                </CustomThemeButton>}
                {!isRunning && <CustomThemeButton theme={timerButtonTheme}
                                                  onClick={() => resumeOnClick().catch((error: any) => throwError(() => handleError(error), licenseDetails.trelloIframeContext, '#contentCardBackSection'))}
                                                  iconAfter={<VidPlayIcon label={'Resume timer'}
                                                                          primaryColor={'#FFFFFF'} size={'small'}/>}>
                    Resume
                </CustomThemeButton>}
                <CustomThemeButton theme={timerButtonTheme} onClick={cancelOnClick}
                                   iconAfter={<CrossCircleIcon label={'Cancel timer'} primaryColor={'#FFFFFF'}
                                                               secondaryColor={'#5243AA'} size={'small'}/>}>
                    Cancel
                </CustomThemeButton>
                <CustomThemeButton theme={timerButtonTheme} onClick={finishOnClick}
                                   iconAfter={<CheckCircleIcon label={'Finish timer'} primaryColor={'#FFFFFF'}
                                                               secondaryColor={'#5243AA'} size={'small'}/>}>
                    Finish
                </CustomThemeButton>
            </ButtonGroup>
            }

        </Grid>
        <CancelModal
            isCancelModalOpen={isCancelModalOpen}
            setIsCancelModalOpen={setIsCancelModalOpen}
            confirmAction={() => confirmModalCancel().catch((error: any) => throwError(() => handleError(error), trelloIframeContext, '#content'))}
            closeAction={closeModalCancel}
            cancelAction={closeModalCancel}
            titleText="Cancel Timer"
            mainBodyText="Are you sure you want to cancel the timer?"
            cancelButtonText="No, go back"
            okButtonText="Yes, cancel"
        />
        <CancelModal
            isCancelModalOpen={isFinishModalOpen}
            setIsCancelModalOpen={setIsFinishModalOpen}
            confirmAction={() => finishModalCancel().catch((error: any) => throwError(() => handleError(error), trelloIframeContext, '#content'))}
            closeAction={closeModalFinish}
            cancelAction={closeModalFinish}
            titleText="Log Time"
            mainBodyText="Are you sure you want to stop the timer and log this time?"
            cancelButtonText="No, go back"
            okButtonText="Yes, log time"
        />
    </>
}