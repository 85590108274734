const USER_DISMISS_ANNOUNCEMENT_UPDATES = 'userDismissAnnouncementUpdates'

/**
 * Check if the current user has dismissed the banner announcement
 * @param trelloContext data needed to store on the trello API
 * @return {Promise<boolean>} true if the user has dismissed or false otherwise
 */
export const hasUserDismissAnnouncementUpdates = async (trelloContext: any): Promise<boolean> => {
    return await trelloContext.get('member', 'private', USER_DISMISS_ANNOUNCEMENT_UPDATES, false)
}
/**
 * Set to true the banner announcement flag for the user. It means the user has dismissed the mini onboarding message
 * @param licenseDetails data needed to store on the trello API
 */
export const setUserDismissAnnouncementUpdates = async (licenseDetails: any) => {
    const trelloContext = licenseDetails.trelloIframeContext
    await trelloContext.set('member', 'private', USER_DISMISS_ANNOUNCEMENT_UPDATES, true)
}